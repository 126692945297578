<template>
	<div class="notice_popup evl">
		<div class="close" @click="$emit('close')">
			<img src="/images/close.png" alt="닫기"/>
		</div>
		<div class="tit">기술인재 평가요청하기</div>
		<div class="member"><span>{{data.corpNm}}</span> 평가담당자 <span class="em">{{data.corpMberNm}} ({{data.corpLoginId}})</span> 에게<br/>
		<tec-summary v-model="tecInfo" :tecMberSeq="data.tecMberSeq" /> 에 대한 기술인재 평가를 요청하시겠습니까?</div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close', false)">취소</div>
			<div class="btn" @click="reqEval()">예</div>
		</div>
	</div>
</template>
<script>
import tecSummary from "@/components/highpro/TecSummary.vue";
export default {
	components: {tecSummary},
	props: { param:Object },
	data() {
		return {
			data: {},
			tecInfo : {},
		};
	},
	mounted(){
		var interviewReqSeq = this.param.interviewReqSeq;
		var projectSeq = this.param.projectSeq;
		var tecMberSeq = this.param.tecMberSeq;
		this.$.httpPost('/api/mem/adm/evl/getEvalInfo', {interviewReqSeq, projectSeq, tecMberSeq})
			.then(res => {
				this.data = res.data;
			}).catch(this.$.httpErrorCommon);
	},
	methods: {
		reqEval(){
			//console.log('reqEval', this.data)
			this.$.httpPost('/api/mem/adm/evl/reqEval', {interviewReqSeq: this.data.interviewReqSeq})
				.then(() => {
					alert('평가요청을 완료하였습니다.');
					this.$emit('close', true);
				})
				.catch(this.$.httpErrorCommon);
		}
	},
};
</script>
